"use client";

import { useState } from "react";
import { locales } from "../../helpers/locales";
import { CosmosFlag } from "@cosmos/web/react";
import { useRouter } from "next/router";
import classnames from "classnames/bind";
import styles from "./country-switcher.module.css";

const cx = classnames.bind(styles);

export interface CountrySwitcherProps {
  initialLocale: string;
}

/**
 * Remove certain locales from the list.
 * Unlaunched locales do not have "localeString"
 */
const filteredLocales = locales.filter(
  (locale) => !("localeString" in locale) || locale.localeString !== "ru-ru",
);

export const CountrySwitcher = ({ initialLocale }: CountrySwitcherProps) => {
  const [selectedLocale, setSelectedLocale] = useState(
    filteredLocales.find(
      (locale) =>
        "localeString" in locale && locale.localeString === initialLocale,
    ),
  );

  const router = useRouter();

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className={cx("select")}>
          <select
            className={cx("select-container")}
            aria-label="select country locale"
            onChange={(event) => {
              const newlySelectedIndex = Number(event.target.value);
              const newLocale = filteredLocales[newlySelectedIndex];

              if (newLocale) {
                router.push(
                  newLocale.launched
                    ? `/${newLocale.localeString}/energydrink`
                    : newLocale.baseWebUrl,
                );
              }
              setSelectedLocale(newLocale);
            }}
            value={filteredLocales.findIndex(
              (locale) => locale === selectedLocale,
            )}
          >
            {filteredLocales.map((locale, index) => (
              <option
                key={index}
                className={cx("option")}
                value={String(index)}
                dir="ltr"
              >
                {locale.countryDisplayName}
              </option>
            ))}
          </select>
          {selectedLocale && (
            <CosmosFlag
              /**
               * Using `key` prop here ensures that the old flag doesn't hang
               * around while the new flag's image is loading.
               */
              key={selectedLocale.flagCode}
              className={cx("flag")}
              role="presentation"
              code={selectedLocale.flagCode}
            />
          )}
        </div>
      </div>
    </div>
  );
};
